var BASE_URL = process.env.REACT_APP_BASE_URL;

var API_BASE_URL = BASE_URL + "member-service/members";
var ONBOARD_API_BASE_URL = process.env.REACT_APP_CMS_BASE_URL + "/api/onboarding-requests";
var UPLOAD_ONBOARD_FILE_URL = process.env.REACT_APP_CMS_FILE_UPLOAD;
var getOnboardToken = process.env.REACT_APP_CMS_ONBOARD_USER_TOKEN;

var APP_ID = "oxs1412";
var TENANT_ID = "FW";

export async function addNewMember(memberDetails, memberToken) {
  //console.log("new member data in service : ", JSON.stringify(memberDetails));
  try {
    const response = await fetch(API_BASE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(memberDetails),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: memberToken,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getMemberById(id, token) {
  const response = await fetch(API_BASE_URL + "/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function getMemberBranches(id, token) {
  const response = await fetch(API_BASE_URL + `/${id}/branches`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "x-tenantid": TENANT_ID,
    },
  });
  //console.log("Fetching details :", response);
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function deleteMemberById(id, token) {
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateMarketsOfMultipleMembers(updatedData, token) {
  try {
    const response = await fetch(API_BASE_URL + "/update/markets-to-develop", {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateMemberById(id, updatedData, token) {
  //console.log("AAA Updated member data in service: ", JSON.stringify(updatedData));
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updatePartnerPriorityById(id, updatedPriority, token) {
  try {
    const response = await fetch(API_BASE_URL + "/potential-partner/" + id, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: updatedPriority,
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function addBranchToMember(headOfficeid, branchId, token) {
  try {
    const response = await fetch(API_BASE_URL + `/${headOfficeid}/update-branches`, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: branchId,
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateMemberContactList(memberId, uid, displayName, token) {
  try {
    const response = await fetch(API_BASE_URL + "/" + memberId + "/update-contacts", {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify({
        userId: uid,
        displayName: displayName,
      }),
    });

    if (!response.ok) {
      throw response;
    } else {
      const body = await response.json();
      return body;
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getListOfMembersFromId(memberIdList, token) {
  // console.log("list", memberIdList);
  try {
    const response = await fetch(API_BASE_URL + "/get-members", {
      method: "POST",
      body: JSON.stringify(memberIdList),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getNewMembers(token, page) {
  try {
    const response = await fetch(API_BASE_URL + "/new", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        offset: page,
        limit: 4,
        query: {
          showInWebsite: true,
        },
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAllMembers(
  data,
  filterList,
  token,
  showInWebsite,
  isEditor,
  offset,
  limit
) {
  let queryFilters;
  if (isEditor) {
    queryFilters = {
      textSearch: data,
      address: data,
      showInWebsite: showInWebsite,
    };
  } else if (data == "") {
    queryFilters = {
      textSearch: data,
      address: data,
      status: "active",
      showInWebsite: true,
      //isDuplicateName: false,
    };
  } else {
    queryFilters = {
      textSearch: data,
      address: data,
      status: "active",
      showInWebsite: true,
    };
  }
  if (filterList !== null) {
    queryFilters = {
      ...queryFilters,
      filters: filterList,
    };
  }
  try {
    const response = await fetch(API_BASE_URL + "/search", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: queryFilters,
        offset: offset,
        limit: limit,
        sortBy: ["companyName"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getFeaturedMembers(token, page) {
  try {
    const response = await fetch(API_BASE_URL + "/search", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: { isFeatured: true },
        offset: page,
        limit: 10,
        sortBy: ["companyName"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getPotentialPartners(token, page, memberId, limit, searchText) {
  try {
    const response = await fetch(API_BASE_URL + `/potential-partners/${memberId}`, {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          textSearch: searchText,
        },
        offset: page,
        limit: limit,
        sortBy: ["companyName"],
        sortOrder: "asc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getPotentialPartnersOfMultipleMembers(token, page, memberIds, limit) {
  try {
    const response = await fetch(API_BASE_URL + "/potential-partners-multiple", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          companyIds: memberIds,
        },
        offset: page,
        limit: limit,
        sortBy: ["companyName"],
        sortOrder: "asc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getImportingFrom(token, page, countries, limit) {
  try {
    const response = await fetch(API_BASE_URL + "/importing-from", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          status: "active",
          country: countries,
          showInWebsite: true,
        },
        offset: page,
        limit: limit,
        sortBy: ["companyName"],
        sortOrder: "asc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getExportingTo(token, page, countries, limit) {
  try {
    const response = await fetch(API_BASE_URL + "/exporting-to", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          status: "active",
          country: countries,
          showInWebsite: true,
        },
        offset: page,
        limit: limit,
        sortBy: ["companyName"],
        sortOrder: "asc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function exportCompanies(token, exportType, days, marketCountry) {
  try {
    const response = await fetch(API_BASE_URL + "/export", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
        "x-export-type": exportType,
        "x-expiry-days": days,
        "x-market-country": marketCountry,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getUsersCompanies(userId, token) {
  try {
    const response = await fetch(API_BASE_URL + "/companies-with-user/" + userId, {
      method: "GET",
      // Adding headers to the request
      headers: {
        Authorization: token,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getExpiringMembers(data, days, token, page) {
  try {
    const response = await fetch(API_BASE_URL + "/expiring-in?days=" + days, {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: { textSearch: data },
        offset: page,
        limit: 10,
        sortBy: ["companyName"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getDepartedMembers(data, token, page) {
  try {
    const response = await fetch(API_BASE_URL + "/departed", {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: { textSearch: data },
        offset: page,
        limit: 10,
        sortBy: ["companyName"],
        sortOrder: "desc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateContactListByID(memberId, contactId, contactName, userToken) {
  try {
    const response = await fetch(API_BASE_URL + "/" + memberId + "/update-contacts", {
      method: "PUT",

      headers: {
        "auth-token": userToken,
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        userId: contactId,
        displayName: contactName,
      }),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function onBoardMember(content) {
  try {
    const response = await fetch(ONBOARD_API_BASE_URL, {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify(content),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function uploadOnboardFile(file) {
  //console.log("file in service : ", file);

  const formdata = new FormData();
  formdata.append("files", file);
  try {
    const response = await fetch(UPLOAD_ONBOARD_FILE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: formdata,

      // Adding headers to the request
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return response.json();
      // return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getAllOnboardingRequests(data, page) {
  try {
    const response = await fetch(
      process.env.REACT_APP_CMS_BASE_URL +
        `/api/onboarding-requests?filters[approved][$eq]=false&filters[company_name][$containsi]=${data}&populate=address&populate=contacts&populate=agentReferences&pagination[page]=${page}&pagination[pageSize]=10`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: getOnboardToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getOnboardingRequestById(id) {
  try {
    const response = await fetch(
      process.env.REACT_APP_CMS_BASE_URL +
        `/api/onboarding-requests/${id}?populate=address&populate=contacts`,
      {
        method: "GET",
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: getOnboardToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading data" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateRequestApprovedStatus(id, isApproved) {
  try {
    const response = await fetch(
      process.env.REACT_APP_CMS_BASE_URL + `/api/onboarding-requests/${id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          data: {
            approved: isApproved,
          },
        }),
        // Adding headers to the request
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: getOnboardToken,
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error updating status" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}
