import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import Header from "layouts/profile/components/Header";
import { Card, CardMedia, Chip, CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import { useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import { Map, View } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Style, Icon } from "ol/style";
import { fromLonLat } from "ol/proj";
import { Feature } from "ol";
import { Point } from "ol/geom";
import markerImage from "assets/images/marker.png";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import { deleteMemberById } from "api_services/MemberService";
import secureLocalStorage from "react-secure-storage";
import { getOnboardingRequestById } from "api_services/MemberService";

function getCurrentDate() {
  let date = new Date();
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = String(date.getFullYear());
  return date.getTime();
  // return yyyy + "-" + mm + "-" + dd;
  // return a date in the form yyyy-mm-dd
}

const containerStyle = {
  width: "100%",
  height: "300px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

function OnboardingRequest() {
  const { id } = useParams();

  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];
  const hasCompanyAccess = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.COMPANY_ACCESS
  ][id];
  const userToken = secureLocalStorage.getItem("custom-token");

  const [memberData, setMemberData] = useState({});
  const [memberAddress, setMemberAddress] = useState([]);
  const [profileText, setProfileText] = useState("");
  // const [userList, setUserList] = useState([]);
  const [contactsList, setContactsList] = useState([]);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [openDia, setOpenDia] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getRequestByID() {
      const response = await getOnboardingRequestById(id, userToken);

      if (response.data !== null) {
        const data = response.data.attributes;
        setIsLoading(false);
        setContactsList(data.contacts);
        setMemberData(data);
        setMemberAddress(data.address);
        // setLatitude(data.location.latitude);
        // setLongitude(data.location.longitude);
        setProfileText(data.companyProfile);
      } else {
        setIsLoading(true);
        //console.log(response);
      }
    }
    getRequestByID();
  }, []);

  //console.log("contact list : ", contactsList);
  const { t } = useTranslation();

  const [delecting, setDelecting] = useState(false);
  async function deleteCompany() {
    setDelecting(true);
    const response = await deleteMemberById(id, userToken);

    if (response !== null) {
      navigate("/directory");
      setOpenDia(false);
      setDelecting(false);
    } else {
      //console.log(response);
    }
  }

  ////////////////////////////////////////////
  // member location map ///////////////////////

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const { latitude, longitude } = position.coords;
  //       // Implement reverse geocoding here to convert coordinates to address
  //       // Set the user address, latitude, and longitude in state
  //       setLatitude(latitude);
  //       setLongitude(longitude);
  //     });
  //   } else {
  //    //console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  const handleDiaClose = () => {
    setOpenDia(false);
  };

  const handleDiaOpen = (id) => {
    setOpenDia(true);
  };

  useEffect(() => {
    if (latitude && longitude) {
      const map = new Map({
        target: "map",
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat([longitude, latitude]),
          zoom: 17,
        }),
      });

      const marker = new Feature({
        geometry: new Point(fromLonLat([longitude, latitude])),
      });

      const iconStyle = new Icon({
        src: markerImage, // Use the imported marker image as the source
        anchor: [0.5, 1],
      });

      const style = new Style({
        image: iconStyle,
      });

      marker.setStyle(style);

      const vectorSource = new VectorSource({
        features: [marker],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      map.addLayer(vectorLayer);
    }
  }, [latitude, longitude]);

  // useEffect(() => {
  //   const markerImages = document.getElementsByClassName("ol-image");
  //   if (markerImages.length > 0) {
  //     const markerImageElement = markerImages[0];
  //     markerImageElement.style.width = "32px";
  //     markerImageElement.style.height = "32px";
  //   }
  // }, []);

  //console.log("Location", latitude, longitude);
  // member location map ///////////////////////

  const isExpired = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getTime() >= date2.getTime();
  };

  const currentDate = getCurrentDate();
  const expiryDate = memberData.expiryDate;
  const expired = isExpired(currentDate, expiryDate);

  const linkedContacts = contactsList?.map(
    ({ id, avatar, fullName, title, email, phoneNumber, linkedinUrl }) => (
      <Grid item xs={12} sm={12} md={5.9} xl={5.9} mb={2}>
        <Card sx={{ px: 2, pt: 2, mt: 2, height: "100%" }}>
          <Grid container spacing={1} display="flex" justifyContent="space-between">
            <Grid
              item
              xs={6}
              sm={3}
              md={2.5}
              xl={2.5}
              component={Link}
              to={`/contacts/profile/${id}`}
            >
              <CardMedia
                src={avatar}
                component="img"
                variant="square"
                mr={2}
                sx={{
                  width: "100%",
                  mb: { xs: 3, sm: 3, md: 0 },
                  margin: 0,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>

            <Grid item xs={12} md={8.5} xl={8.5} display="flex" flexDirection="column">
              <MDBox mt={-0.5} display="flex" justifyContent="space-between" alignItems="start">
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color="black"
                  // component={Link}
                  // to={`/contacts/profile/${id}`}
                >
                  {fullName}
                  <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="black">
                      {title}
                    </MDTypography>
                  </MDBox>
                </MDTypography>

                <MDBox
                  component="a"
                  href={linkedinUrl}
                  target="_blank"
                  variant="button"
                  color="info"
                >
                  <LinkedInIcon fontSize="medium" />
                </MDBox>
              </MDBox>

              <MDBox variant="button" color="primary" mt={1} display="flex" alignItems="center">
                <EmailIcon fontSize="small" />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="black"
                  ml={2}
                  component="a"
                  href={`mailto:${email}`}
                  target="_blank"
                >
                  {email}
                </MDTypography>
              </MDBox>
              <MDBox variant="button" color="primary" mt={1} display="flex" alignItems="center">
                <PhoneIcon fontSize="small" />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="black"
                  ml={2}
                  component="a"
                  href={`tel:${phoneNumber}`}
                  target="_blank"
                >
                  {phoneNumber}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  );

  {
    /* <TableRow key={id}>
      <TableCell>
        <MDBox mr={3}>
          <MDAvatar
            src={avatar}
            alt="something here"
            shadow="md"
            size="xl"
            variant="square"
            sx={{ borderRadius: 3 }}
          />
        </MDBox>
      </TableCell>

      <TableCell>
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium" color="black">
            {displayName}
          </MDTypography>

          <MDTypography variant="button" color="black">
            {title}
          </MDTypography>
        </MDBox>
      </TableCell>

      <TableCell>
        <MDBox display="flex" alignItems="center" justifyContent="start">
       
          <MDBox component="a" href={email} variant="button" color="info" mt={1}>
            <LinkedInIcon fontSize="medium" />
          </MDBox>
          <MDBox component="a" href={email} variant="button" color="text" mt={1} ml={1}>
            <EmailIcon fontSize="medium" />
          </MDBox>
        </MDBox>
      </TableCell>
    </TableRow> */
  }

  // const editProfile = (
  //   <Grid
  //     container
  //     pt={0}
  //     pl={1}
  //     pr={0}
  //     mb={5}
  //     mt={2}
  //     lineHeight={1.25}
  //     display="flex"
  //     alignItems="center"
  //     justifyContent="space-between"
  //   >
  //     <Grid
  //       item
  //       xs={12}
  //       md={6}
  //       xl={5}
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="flex-start"
  //     >
  //       <MDAvatar
  //         src={burceMars}
  //         alt="profile-image"
  //         size="xxl"
  //         shadow="sm"
  //         variant="square"
  //         sx={{ borderRadius: 3 }}
  //       />
  //       <MDBox ml={5}>
  //         <MDTypography variant="h5" fontWeight="medium" color="black">
  //           {memberData.companyName}
  //         </MDTypography>
  //         <MDTypography variant="button" color="black">
  //           {memberAddress.country}
  //         </MDTypography>
  //       </MDBox>
  //     </Grid>

  //     <Grid
  //       item
  //       xs={12}
  //       md={4}
  //       xl={4}
  //       mt={4}
  //       display="flex"
  //       alignItems="center"
  //       sx={{ justifyContent: { xs: "start", md: "end" }, mt: { xs: 4, md: 0 } }}
  //     >
  //       {/* <MDButton size="medium" color="info">
  //         Export
  //       </MDButton> */}

  //       <MDButton
  //         size="medium"
  //         variant="outlined"
  //         color="primary"
  //         // sx={{ ml: 2 }}
  //         component={Link}
  //         to={`/update-member/${id}`}
  //       >
  //         Edit Profile
  //       </MDButton>
  //     </Grid>
  //   </Grid>
  // );

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY,
  // });

  // const [map, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return (
    <>
      <style>
        {` .ql-snow .ql-stroke , .ql-snow .ql-picker {
            stroke: #7b809a;
            color:#7b809a;
            font-family: 'poppins;
          }
          .ql-snow .ql-picker.ql-expanded .ql-picker-options {
            z-index: 9;
          }
          #map li {
            display:none;
          }

          .ol-image{
            width:"50";
          }
          
          `}
      </style>
      <DashboardLayout>
        <DashboardNavbar />
        <Header>
          {isLoading ? (
            // Render a spinner or loading indicator
            <Stack spacing={3}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "0.8rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <MDBox display="flex">
                <Skeleton variant="circular" width={160} height={140} sx={{ mr: 5 }} />
                <Skeleton variant="rectangular" width={"100%"} height={140} />
              </MDBox>

              <Skeleton variant="rectangular" width={"100%"} height={20} />
              <Skeleton variant="rounded" width={"100%"} height={200} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
            </Stack>
          ) : (
            <MDBox mx={1} mb={2}>
              <Grid container spacing={1} display="flex" justifyContent="space-between">
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={5}
                  my={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <MDBox>
                    <MDTypography variant="h5" fontWeight="medium" color="black">
                      {memberData.companyName}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>

              {memberData.showBanner ? (
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={1}
                >
                  <MDBox
                    sx={{
                      background: "#FFF5F5",
                      border: "1px solid #FFE2DF",
                      borderRadius: "5px 5px 0 0",
                      width: "100%",
                    }}
                  >
                    <MDTypography
                      variant="h4"
                      textAlign="center"
                      fontWeight="medium"
                      color="error"
                      px={2}
                      py={1}
                    >
                      {memberData.bannerTitle}
                    </MDTypography>
                  </MDBox>
                  {memberData.bannerBody !== null && memberData.bannerBody.length > 0 ? (
                    <MDBox
                      sx={{
                        background: "#FDF8E3",
                        border: "1px solid #FDEFD3",
                        borderRadius: "0 0 5px 5px",
                        width: "100%",
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        textAlign="center"
                        fontWeight="light"
                        sx={{ color: "#8D7240" }}
                        px={2}
                        py={1}
                      >
                        {memberData.bannerBody}
                      </MDTypography>
                    </MDBox>
                  ) : null}
                </Grid>
              ) : null}

              <Grid container spacing={1} display="flex" justifyContent="space-between" mt={5}>
                <Grid item xs={8} md={2} xl={2}>
                  <CardMedia
                    src={memberData.companyLogo}
                    component="img"
                    variant="square"
                    sx={{
                      width: "124.8px",
                      height: "124.8px",
                      mb: { xs: 3, sm: 3, md: 0 },
                      margin: 0,
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  display="flex"
                  justifyContent="start"
                  flexDirection="column"
                >
                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Country : </b>
                      <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                        {memberAddress?.country}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>

                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="start">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Address : </b>
                      <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                        {memberAddress?.street +
                          ", " +
                          memberAddress?.city +
                          ", " +
                          memberAddress?.country +
                          ", " +
                          memberAddress?.postcode +
                          "."}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>

                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Email : </b>
                      <MDTypography
                        variant="button"
                        fontWeight="light"
                        mr={1}
                        color="black"
                        component="a"
                        href={`mailto:${memberData.email}`}
                        target="_blank"
                      >
                        {memberData.email}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  display="flex"
                  justifyContent="start"
                  flexDirection="column"
                >
                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Office No : </b>
                      <MDTypography
                        variant="button"
                        fontWeight="light"
                        mr={1}
                        color="black"
                        component="a"
                        href={`tel:${memberData.officeContactNumber}`}
                        target="_blank"
                      >
                        {memberData.officeContactNumber}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1.5} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>LinkedIn :</b>
                    </MDTypography>
                    <MDBox
                      component="a"
                      href={memberData.linkedInUrl}
                      target="_blank"
                      variant="button"
                      fontWeight="light"
                      color="black"
                    >
                      <LinkedInIcon fontSize="medium" color="info" />
                    </MDBox>
                  </MDBox>

                  <MDBox mb={1.5} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Website :</b>
                    </MDTypography>
                    <MDBox
                      component="a"
                      href={memberData.websiteUrl}
                      target="_blank"
                      variant="button"
                      fontWeight="light"
                      color="black"
                    >
                      <LanguageIcon fontSize="medium" color="primary" />
                    </MDBox>
                  </MDBox>
                </Grid>

                {/* Locations shown on map */}
                {/* <Grid item xs={12} sm={12} md={12} xl={12} mt={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Location
                  </MDTypography>
                  <MDBox mt={2}>
                    {latitude && longitude ? (
                      <div id="map" style={{ width: "100%", height: "250px" }}></div>
                    ) : (
                      <MDTypography
                        variant="button"
                        display="flex"
                        justifyContent="start"
                        color="black"
                      >
                        No Location found !
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12} xl={12} mt={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Company Profile :
                  </MDTypography>
                  <MDBox mt={1}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                      color="black"
                      align="justify"
                      dangerouslySetInnerHTML={{ __html: memberData.companyProfile }}
                    ></MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={12} mt={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Markets Looking to Develop In :
                  </MDTypography>
                  <MDBox
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "center",
                      overflowX: "auto",
                      minHeight: 42.4,
                      gap: 1,
                      color: "white",
                    }}
                    mt={1}
                  >
                    {memberData.marketsToDevelop.map((value) => (
                      <Chip
                        key={value}
                        label={
                          <MDTypography variant="button" color="black">
                            {value}
                          </MDTypography>
                        }
                      />
                    ))}
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={12} mt={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Contacts :
                  </MDTypography>

                  <MDBox mt={1}>
                    {contactsList?.length !== 0 ? (
                      <Grid container spacing={1} display="flex" justifyContent="space-between">
                        {linkedContacts}
                      </Grid>
                    ) : (
                      <Grid>
                        <MDTypography
                          variant="button"
                          display="flex"
                          justifyContent="start"
                          color="black"
                        >
                          No contacts found !
                        </MDTypography>
                      </Grid>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </Header>

        <Footer />
      </DashboardLayout>

      {/* Dialog Popup //////////////////////////////////////////// */}

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={openDia}
          onClose={handleDiaClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={5} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Are you sure you want to remove company?
            </MDTypography>
            {delecting && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
          <MDBox mt={2} mb={4} display="flex" justifyContent="center">
            <MDBox mr={2}>
              <MDButton
                onClick={handleDiaClose}
                autoFocus
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDButton
              autoFocus
              variant="gradient"
              color="primary"
              onClick={() => deleteCompany()}
              size="small"
            >
              Confirm
            </MDButton>
          </MDBox>
        </Dialog>
      </MDBox>

      {/* //////////////////////////////////////////// */}
    </>
  );
}

export default OnboardingRequest;
